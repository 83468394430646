/**
  转化宝菜单配置文件
  如果第一级有component，则走自定义逻辑，参考“AI助手”
 */
import dynamic from 'store/dynamic';
import {
  // IconWechat,
  IconAILab,
} from 'maker/components/Icon/icon';

import { TOOL_MENU_ID } from './const';

import AiLabSidderMenu from './aiLabSidderMenu';
import {
  AI_LAB_ICONS,
  AI_LAB_LINKS,
  AI_LAB_NAMES,
  AiLabTypeSceneEnum,
  AI_LAB_TYPE_TITLES,
} from '@/maker/common/aiLabConst';

// 各页面的路由链接

// !important 注意。如果去除了customsidderMenu，会渲染失败。因为自定义sidder，跟他在layout的模块渲染方式不同。

import { FeatureTags } from 'maker/config';
import { WHITE_LIST } from '@/maker/common/systemConst';

export const AILabData =  {
  id: TOOL_MENU_ID,
  key: 'aiLab',
  name: 'AI体验室',
  newTag: FeatureTags.AI_IS_FIRST_VISIT,      // 显示“new”标识
  icon: IconAILab,

  menuEnableWhitelistKey: WHITE_LIST.AI_LAB, // 白名单显示菜单逻辑
  CustomSidderMenu: AiLabSidderMenu,
  children: [
    {
      id: TOOL_MENU_ID, // 没有给成员分独立权限的必要。所以用上面的
      key: 'ai-home',
      name: '首页',
      url: AI_LAB_LINKS[AiLabTypeSceneEnum.HOME],
      icon: null,
      component: dynamic({
        component: () => import('@/maker/routes/AiLab/Home'),
      }),
      hideSidder: true,
      hideMenuItem: true,
    },
    {
      id: TOOL_MENU_ID, // 没有给成员分独立权限的必要。所以用上面的
      key: 'ai-history',
      name: '历史记录',
      url: AI_LAB_LINKS[AiLabTypeSceneEnum.HISTORY],
      icon: null,
      component: dynamic({
        component: () => import('@/maker/routes/AiLab/History'),
      }),
      hideSidder: true,
      hideMenuItem: true,
    },
    {
      menuType: 'title',
      name: AI_LAB_TYPE_TITLES.MARKETING,
    },
    {
      id: TOOL_MENU_ID, // 没有给成员分独立权限的必要。所以用上面的
      key: 'ai-productPromo',
      name: AI_LAB_NAMES[AiLabTypeSceneEnum.PRODUCT_PROMO],
      url: AI_LAB_LINKS[AiLabTypeSceneEnum.PRODUCT_PROMO],
      icon: AI_LAB_ICONS[AiLabTypeSceneEnum.PRODUCT_PROMO],
      component: dynamic({
        component: () => import('@/maker/routes/AiLab/ProductPromo'),
      }),
      desc: '输入商品信息，智能生成多平台风格的商品推广文案',
      filters: [AI_LAB_TYPE_TITLES.MARKETING],
    },
    // // 把对应的title以及对应的menu配置注释后，就无法在主页/菜单看到了
    // {
    //   menuType: 'title',
    //   name: AI_LAB_TYPE_TITLES.OFFICE_EFFICIENCY,
    // },
    // {
    //   parentId: TOOL_MENU_ID,
    //   key: 'ai-pdfAnalyze',
    //   name: AI_LAB_NAMES[AiLabTypeSceneEnum.PDF_ANALYZE],
    //   url: AI_LAB_LINKS[AiLabTypeSceneEnum.PDF_ANALYZE],
    //   icon: AI_LAB_ICONS[AiLabTypeSceneEnum.PDF_ANALYZE],
    //   // TODO: 新建页面组件
    //   component: dynamic({
    //     component: () => import('@/maker/routes/AiLab/ProductPromo'),
    //   }),
    //   desc: '利用智能模型，上传PDF文件<br>快速提取文档核心观点，高效概括文档内容',
    //   filters: [AI_LAB_TYPE_TITLES.OFFICE_EFFICIENCY],
    // },
    // {
    //   parentId: TOOL_MENU_ID,
    //   key: 'ai-officialAccountArticle',
    //   name: AI_LAB_NAMES[AiLabTypeSceneEnum.OFFICIAL_ACCOUNT_ARTICLE],
    //   url: AI_LAB_LINKS[AiLabTypeSceneEnum.OFFICIAL_ACCOUNT_ARTICLE],
    //   icon: AI_LAB_ICONS[AiLabTypeSceneEnum.OFFICIAL_ACCOUNT_ARTICLE],
    //   // TODO: 新建页面组件
    //   component: dynamic({
    //     component: () => import('@/maker/routes/AiLab/ProductPromo'),
    //   }),
    //   desc: '智能算法解析公众号文章URL，<br>速总结核心观点，助您快速获取关键信息',
    //   filters: [AI_LAB_TYPE_TITLES.OFFICE_EFFICIENCY],
    // },
    {
      menuType: 'title',
      name: AI_LAB_TYPE_TITLES.CUSTOMERS,
    },
    {
      id: TOOL_MENU_ID, // 没有给成员分独立权限的必要。所以用上面
      key: 'ai-customerService',
      name: AI_LAB_NAMES[AiLabTypeSceneEnum.CUSTOMER_SERVICE],
      url: AI_LAB_LINKS[AiLabTypeSceneEnum.CUSTOMER_SERVICE],
      icon: AI_LAB_ICONS[AiLabTypeSceneEnum.CUSTOMER_SERVICE],
      component: dynamic({
        component: () => import('@/maker/routes/AiLab/CustomerService'),
      }),
      desc: '专业行业百科知识，智能客服为您答疑解惑，带来高质量内容',
      filters: [AI_LAB_TYPE_TITLES.CUSTOMERS],
    },
    {
      id: TOOL_MENU_ID, // 没有给成员分独立权限的必要。所以用上面的
      key: 'ai-openingTalk',
      name: AI_LAB_NAMES[AiLabTypeSceneEnum.OPEN_TALK],
      url: AI_LAB_LINKS[AiLabTypeSceneEnum.OPEN_TALK],
      icon: AI_LAB_ICONS[AiLabTypeSceneEnum.OPEN_TALK],
      component: dynamic({
        component: () => import('@/maker/routes/AiLab/BreakIce'),
      }),
      desc: '输入用户信息生成欢迎语和开口话术，多种风格以供选择',
      filters: [AI_LAB_TYPE_TITLES.CUSTOMERS],
    },
    {
      id: TOOL_MENU_ID, // 没有给成员分独立权限的必要。所以用上面的
      key: 'ai-customerCare',
      name: AI_LAB_NAMES[AiLabTypeSceneEnum.CUSTOMER_CARE],
      url: AI_LAB_LINKS[AiLabTypeSceneEnum.CUSTOMER_CARE],
      icon: AI_LAB_ICONS[AiLabTypeSceneEnum.CUSTOMER_CARE],
      component: dynamic({
        component: () => import('@/maker/routes/AiLab/CustomerCare'),
      }),
      desc: '提供不同节庆假期的客户话术参考，多种风格以供选择',
      filters: [AI_LAB_TYPE_TITLES.CUSTOMERS],
    },
  ],
};
