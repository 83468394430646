
import './index.less';
import React from 'react';
// interface UrlInfo {
//   label: string;
//   url: string;
// }

// const list1: UrlInfo[] = [
//   {
//     label: '腾讯社交广告营销平台',
//     url: '',
//   },
//   {
//     label: '腾讯社交广告联盟',
//     url: '',
//   },
//   {
//     label: '腾讯社交广告数据管理平台',
//     url: '',
//   },
// ];
// const list2: UrlInfo[] = [
//   {
//     label: '关于腾讯',
//     url: '',
//   },
//   {
//     label: '服务条款',
//     url: '',
//   },
//   {
//     label: '隐私保护',
//     url: '',
//   },
//   {
//     label: '版权保护投诉指引',
//     url: '',
//   },
// ];

const Footer = () => {
  // const renderUrlModule = (data: UrlInfo[]) => (
  //   <div className='url-show-module'>
  //     {
  //       data.map((item, index) => (
  //         <a key={index} href={item.url} className='url-show-item'>{item.label}</a>
  //       ))
  //     }
  //   </div>
  // );
  const getFullYear = () => {
    const date = new Date();
    const year = date .getFullYear();
    return year;
  };
  return (
    <div className='module-footer'>
      {/* {renderUrlModule(list1)}
      {renderUrlModule(list2)} */}
      <div className='url-show-module'>Copyright © 1998-{getFullYear()}Tencent Inc.All Rights Reserved.腾讯公司 版权所有</div>
    </div>
  );
};

export default Footer;
