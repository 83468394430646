/**
  特殊菜单常量
 */

export const SETTING_MENU_ID = 14; // 设置
export const TOOL_MENU_ID = 12; // 工具箱ID
export const WECHAT_MUNU_ID = 22;  // AI文案助手
export const SCRM_MUNU_ID = 15;  // SCRM
export const DATA_MUNU_ID = 13;  // 数据服务
export const MARKETING_MUNU_ID = 16;  // 数据服务
export const ANALYSIS_ID = 17; // 全链路分析
export const SHOPPING_MUNU_ID = 18;  // 导购辅助
