/*
 * @Author: hillliu
 */
import { useContext } from 'react';

import Context from 'maker/stores/context';

/** 获取全局配置 */
export function useGlobalStore() {
  const { state } = useContext<any>(Context);
  return state;
}

/** 获取全局provider */
export function useGlobalContext() {
  const { state, dispatch } = useContext<any>(Context);
  return {
    state,
    dispatch,
  };
}
