export interface FYResponse<C = number | string> {
  code: C;
  data?: any;
  msg?: string; // "Success"(成功)
  message?: string;
  traceId?: string;
}
export default class ResponseHelper<C = number | string> {
  response: FYResponse<C>;

  constructor(response: FYResponse<C>) {
    this.response = response;
  }

  isSuccess() {
    return (typeof this.response.code === 'number' && this.response.code === 200)
      || this.response.code === 'Success';
  }

  getMessage() {
    if (this.response.message) {
      return this.response.message;
    }
    if (this.response.msg) {
      return this.response.msg;
    }
    return '请求出错';
  }

  getData() {
    return this.response.data;
  }

  setData(data: any) {
    return (this.response.data = data);
  }

  getResponse() {
    return this.response;
  }
}
