import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import dynamic from 'store/dynamic';
import { hot } from 'react-hot-loader/root';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { MENU_DATA } from 'maker/config';
import Layout from './layouts/index';

import GlobalDataProvider from './stores/provider';
import HomePage from './routes/Home';
import NotFoundPage from './routes/Home/404';

// 全局样式
import 'maker/components/Style';
// 递归遍历数组获取所有路由
const loopChild: any = (data: any) => {
  let newItem: any = [];
  for (const item of data) {
    if (item.children && item.children.length > 0) {
      newItem = newItem.concat(loopChild(item.children));
    } else if (item.url) {
      newItem.push({
        path: item.url,
        exact: true,
        component: item.component,
      });
    }
  }
  return newItem;
};

const App = () => {
  const routes = loopChild(MENU_DATA);
  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <GlobalDataProvider>
          <Switch>
            {/* 登录相关页面 */}
            <Route path={'/'} exact component={HomePage} />
            <Route path={'/login'} exact component={dynamic({
              component: () => import('./routes/Login'),
            })} />
            <Route path={'/login/ssoLogin'} exact component={dynamic({
              component: () => import('./routes/Login/access'),
            })} />
            <Route path={'/login/mlogin'} exact component={dynamic({
              component: () => import('./routes/Login/super'),
            })} />
            <Route path={'/login/register'} exact component={dynamic({
              component: () => import('./routes/Login/register'),
            })} />
            {/* 不带菜单的页面 */}
            <Route path={'/normal/adOpIntro'} exact component={dynamic({
              component: () => import('./routes/DataReport/adoptimize/introduce'),
            })} />
            <Route path={'/normal/sandIntro'} exact component={dynamic({
              component: () => import('./routes/Marketing/sandtable/introduce'),
            })} />
            <Route path={'/normal/experiment/queryDetailInfo'} exact component={dynamic({
              component: () => import('./routes/Experiment/QueryDetailInfo'),
            })} />
            {/* 404页面 */}
            <Route path={'/404'} component={NotFoundPage} />

            {/* 带菜单的页面 */}
            <Layout>
              <QueryParamProvider adapter={ReactRouter5Adapter}>
                {renderRoutes(routes)}
              </QueryParamProvider>
            </Layout>
          </Switch>
        </GlobalDataProvider>
      </BrowserRouter>
    </DndProvider>
  );
};

export default hot(App);
