import { Request } from '@common/requestHelper';
import { authApi } from 'maker/config';


// 获取角色列表
export const getRoleList = async (params: any) => Request.post(authApi.getRoleList, params);

// 创建角色
export const createRole = async (params: any) => Request.post(authApi.createRole, params);

// 更新/删除角色
export const updateRole = async (params: any) => Request.post(authApi.updateRole, params);

// 获取子账号列表
export const getSubAccountList = async (params: any) => Request.post(authApi.getSubAccountList, params);

// 创建子账号
export const createSubAccount = async (params: any) => Request.post(authApi.createSubAccount, params);

// 更新子账号
export const updateSubAccount = async (params: any) => Request.post(authApi.updateSubAccount, params);

// 修改密码
export const changePassword = async (params: any) => Request.post(authApi.changePassword, params);

// 广告账户-授权列表
export const getBindAccountList = async (params: any) => Request.post(authApi.getBindAccountList, params);

// 广告账户-新增授权
export const createBindAccount = async (params: any) => Request.post(authApi.createBindAccount, params);

// 广告账户-同意/拒绝/删除授权
export const updateBindAccount = async (params: any) => Request.post(authApi.updateBindAccount, params);

//  修改名称
export const modifyName = async (params: any) => Request.post(authApi.modifyName, params);


