import axios, { isCancel } from 'axios';
import ResponseHelper, { FYResponse } from '@/common/responseHelper';

/**
 * 如果Request.get和Request.post不满足需求情况下，可以使用Request(options)来做请求
 * @param {*} options 参考axios文档
 */
export const Request = (options: any): any => axios({ withCredentials: true, ...options }).catch((error) => {
  if (isCancel(error)) {
    throw error;
  }

  let response = {
    code: 0,
    msg: '请求失败',
  };

  if (error?.response?.data) {
    response = error.response.data;
  }

  const errorResponse = new ResponseHelper<string | number>(response);

  return errorResponse;
});

/**
 * 配置请求
 * @param {any} options
 */
Request.setting = (options: any) => {
  const {
    beforeRequest = (config: any) => {
      config.headers['system-code'] = 'FY';
      return config;
    },
    beforeResponse = (response: FYResponse) => response,
  } = options;

  axios.interceptors.request.use(config => beforeRequest(config));

  axios.interceptors.response.use(response => beforeResponse(response));
};

/**
 * get的请求
 * @param {string} url 请求地址
 * @param {any} data 请求参数
 * @param {any} options 请求的axios配置，参考axios文档
 */
Request.get = (url: string, data?: any, options = {}) => Request({
  url,
  method: 'get',
  params: data,
  ...options,
});

/**
 * post的请求
 * @param {string} url 请求地址
 * @param {any} data 请求参数
 * @param {any} options 请求的axios配置，参考axios文档
 */
Request.post = (url: string, data?: any, options: any = {}) => Request({
  url,
  method: 'post',
  data,
  ...options,
});

export default Request;
