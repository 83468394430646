import React, { useMemo } from 'react';
import Logo from 'maker/assets/wechatAI/logo-title.svg';
import styles from './header.less?local';
import { useGlobalContext } from '../hooks/useGlobalStore';
import { getFeature } from 'maker/utils/session';
import { SETTING_MENU_ID } from 'maker/config/menu/const';
import { IconSetting, IconHelp } from 'maker/components/Icon/icon';
import { Link } from 'react-router-dom';
// import UserInfo from '@/maker/layouts/UserInfo';
import { CorpSelect, UserInfo } from 'maker/components/MixSelect';
import { FeatureTags } from 'maker/config';
import { WHITE_LIST } from 'maker/common/systemConst';
import classNames from 'classnames';
import withWhitelist from '../use/withWhitelist';
interface MenuModuleProps {
  path: string;
  history: any;
  selectIndex: number;
}

type TypeMenuLevel1Item = {
  key: string;
  id: number;
  name: string;
  newTag?: FeatureTags;
  icon?: React.ComponentType<any>;
  menuEnableWhitelistKey?: typeof WHITE_LIST[keyof typeof WHITE_LIST];
};

const getMenuIndex = (menuData: any, menuId: number) => {
  for (let i = 0; i < menuData.length; i++) {
    if (menuData[i].id === menuId) {
      return i;
    }
  }
  return false;
};

const isNew = (index: FeatureTags | undefined) => {
  if (!index) {
    return false;
  }
  return !getFeature(index);
};

const HeaderModule = (props: MenuModuleProps) => {
  const { state, dispatch } = useGlobalContext();
  const { MENU, history } = state;
  const { menuData } = MENU;

  // 定位到有权限的第一个页面
  const changeTab = (index: number | boolean) => {
    let url = '';
    if (index !== false) {
      const menu = MENU.getFirstMenu(index);
      if (menu) {
        url = menu.url;
      }
    }
    if (url) {
      dispatch({
        type: 'setData',
        data: { selectMenuIndex: index, currUrlPath: history.location.pathname },
      });
      history.push(url);
    }
  };

  const settingMenuIndex = getMenuIndex(menuData, SETTING_MENU_ID);

  // authList 是店铺内部管理的名单，用于给单个帐户分配权限用的。有权限才能看到菜单
  // whitelist hook提供的是全站的针对店铺的白名单。店铺人员不可自行分配
  const renderMenuItem = useMemo(() => (
    item: TypeMenuLevel1Item,
    isActive: boolean,
    onClick = () => {},
  ) => {
    // 设置菜单不在这里处理
    if (SETTING_MENU_ID === item.id) {
      return null;
    }
    const cls = classNames({
      [styles['header-items']]: true,
      [styles['selected-item']]: isActive,
      [styles.new]: isNew(item.newTag),
    });

    // 平台管理的，店铺维度白名单
    const MenuFc = withWhitelist(
      () => (
        <div
          className={cls}
          onClick={onClick}>
          {item.icon && (<span className={styles['menu-icon']}><item.icon /></span>)}
          {item.name}
          <div className={styles['slected-line']}></div>
        </div>
      ),
      item.menuEnableWhitelistKey || '',
      () => null,
    );
    return <MenuFc key={`${item.key}_${item.name}`} />;
  }, []);


  return useMemo(() => (<div className={styles['header-wrapper']}>
    <div className={styles.leftDiv}>
      <div className={styles['header-wrapper-logo']}>
        <Link to="/" title='首页'><img src={Logo} alt="logo" /></Link>
      </div>
      <div className={styles['header-wrapper-tabs']}>
        {
          menuData.map((item: TypeMenuLevel1Item, index: number) => (
            renderMenuItem(item, index === props.selectIndex, () => changeTab(index))
          ))
        }
      </div>
    </div>
    <div className={styles.rightDiv}>
      {settingMenuIndex !== false && (<div
        className={`${styles.setting} ${settingMenuIndex === props.selectIndex ? styles['right-selected'] : ''}`}
        onClick={() => {
          changeTab(settingMenuIndex);
        }}>
        <div className={styles.icon}>
          <IconSetting selected={settingMenuIndex === props.selectIndex} />
        </div>
        设置
      </div>)}

      <div className={styles.setting} onClick={() => {
        window.open('https://docs.qq.com/doc/DTVFKamNOZkVuU2RN');
      }}>
        <div className={styles.icon}>
          <IconHelp />
        </div>
        帮助
      </div>
      <div className={styles.split}></div>
      <CorpSelect />
      <div className={styles.split}></div>
      <UserInfo />
    </div>
  </div >), [
    menuData,
    settingMenuIndex,
    props.selectIndex,
  ]);
};

export default HeaderModule;
