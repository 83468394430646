/**
  转化宝菜单配置文件
  如果第一级有component，则走自定义逻辑
 */
import dynamic from 'store/dynamic';
import {
  IconShopingHelp,
  IconSvgAiShopping,
  IconSvgShoppingSop,
} from 'maker/components/Icon/icon';

import { SHOPPING_MUNU_ID } from './const';
import { WHITE_LIST } from '@/maker/common/systemConst';
import { FeatureTags } from 'maker/config';

export const ShoppingHelpData = {
  id: SHOPPING_MUNU_ID,
  name: '导购辅助',
  url: '/shopppingHelp',
  icon: IconShopingHelp,
  menuEnableWhitelistKey: WHITE_LIST.SHOPPING_HELP, // 白名单显示菜单逻辑
  newTag: FeatureTags.SHOPPING_HELP_IS_FIRST_VISIT,      // 显示“new”标识

  children: [
    {
      id: 211,
      name: 'AI导购',
      url: '/shoppingHelp/ai',
      icon: IconSvgAiShopping,
      component: dynamic({
        component: () => import('maker/routes/ShoppingHelp/aiShopping'),
      }),
    },
    // {
    //   id: 214,
    //   name: 'AI导购微前端',
    //   url: '/shoppingHelp/aiTest',
    //   icon: IconSvgAiShopping,
    //   component: dynamic({
    //     component: () => import('maker/routes/ShoppingHelp/subAiShopping'),
    //   }),
    // },
    {
      id: 212,
      name: '导购知识库',
      url: '/shoppingHelp/sop',
      icon: IconSvgShoppingSop,
      component: dynamic({
        component: () => import('maker/routes/ShoppingHelp/sopRepo'),
      }),
    },
  ],
};
