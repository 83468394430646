
import './index.less';
import React, { useState } from 'react';
import { Button } from '@tencent/tea-component';
import { useGlobalStore } from '@/maker/hooks/useGlobalStore';
import { SvgHelp } from 'maker/components/Icon/icon';
import img1 from 'maker/assets/homepage/experience0.png';
import img1Not from 'maker/assets/homepage/experience0-not.png';
import img2 from 'maker/assets/homepage/experience1.png';
import img2Not from 'maker/assets/homepage/experience1-not.png';
import img3 from 'maker/assets/homepage/experience2.png';
import img3Not from 'maker/assets/homepage/experience2-not.png';
import img4 from 'maker/assets/homepage/experience3.png';
import img4Not from 'maker/assets/homepage/experience3-not.png';
import { goHomepage } from 'maker/utils/router';
const experienceList = [
  {
    label: '教育行业',
    bgUrl: img1,
    bgUrlNot: img1Not,
    infoList: ['客户兴趣难洞察', '参课率低', '销售离职带走生源'],
    proportionList: [
      {
        label: '首次参课率',
        val: '+23%',
      },
      {
        label: '首次参课成本',
        val: '-22%',
      },
    ],
  },
  {
    label: '电商行业',
    bgUrl: img2,
    bgUrlNot: img2Not,
    infoList: ['客户活跃度低', '营销活动效果差', '商品推荐转化低'],
    proportionList: [
      {
        label: '开口成本',
        val: '-7%',
      },
      {
        label: '加粉转化率',
        val: '+29%',
      },
    ],
  },
  {
    label: '医药行业',
    bgUrl: img3,
    bgUrlNot: img3Not,
    infoList: ['医护团队难管理', '沉默用户占比大', '下单率低'],
    proportionList: [
      {
        label: '下单率',
        val: '+4.1%',
      },
      {
        label: '开口成本',
        val: '-14%',
      },
    ],
  },
  {
    label: '器械行业',
    bgUrl: img4,
    bgUrlNot: img4Not,
    infoList: ['客户转化行为难统计', '导购专业能力差异大', '转化效率低于预期'],
    proportionList: [
      {
        label: '开口成本',
        val: '-17%',
      },
      {
        label: '加粉转化率',
        val: '+33%',
      },
    ],
  },
];

const ExperienceModule = () => {
  const [activeItem, setActiveItem] = useState(0);
  const state = useGlobalStore();
  const { nickName, history } = state;
  const renderExpeienceItem = (param, index: any) => {
    const { label, iconC } = param;
    return (
      <div key={index} className='expeience-item'>
        {/* <div className='check-icon'>
        </div> */}
        <SvgHelp color={iconC} />
        <span className='expeience-item-label'>{label}</span>
      </div>
    );
  };
  const getIsActive = index => index === activeItem;
  const renderProportion = (item, index) => (
    <div key={index} className='proportion-item'>
      <div className='proportion-val'>{item.val}</div>
      <div>{item.label}</div>
    </div>
  );
  const gotoUse = () => {
    if (nickName) {
      goHomepage(state);
    } else {
      history.push('/login');
      // goLoginPage(history);
    }
  };
  const renderExp = (itemInfo, index) => {
    const isActive = getIsActive(index);
    return (
      <div
        key={index}
        onMouseEnter={() => setActiveItem(index)}
        className={`experience-detail-show ${isActive ? 'active-experience' : 'sub-experience'}`}
        style={{ backgroundImage: isActive ? `url(${itemInfo.bgUrl})` : `url(${itemInfo.bgUrlNot})` }}>
        <div className='main-experience-label'>{`${itemInfo.label}${isActive ? '客户案例' : ''}`}</div>
        {
          itemInfo.infoList.map((item, index) => renderExpeienceItem(
            {
              label: item,
              labelC: '#030303',
              iconC: isActive ? '#296BEF' : '#fff',
            },
            index,
          ))
        }
        <div style={{ display: isActive ? 'block' : 'none' }} className='active-detail-show'>
          <div className='discription-content'>使用转化宝后：</div>
          <div className='proportion-module'>
            {
              itemInfo.proportionList.map((item, index) => renderProportion(item, index))
            }
          </div>
          <div className='consult-module'>
            <Button className='consult-btn' onClick={gotoUse}>立即免费使用</Button>
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className='landing-page-experience'>
      <div className='experience-title'>全行业落地经验</div>
      <div className='experience-title'>提供行业差异化产品适配及运营支持</div>
      <div className='landing-page-experience-content'>
        {
          experienceList.map((item, index) => renderExp(item, index))
        }
      </div>
    </div>
  );
};

export default ExperienceModule;
