/**
  转化宝菜单配置文件
  如果第一级有component，则走自定义逻辑，参考“AI助手”
 */
import dynamic from 'store/dynamic';
import {
  IconMarketingSandtable,
  IconRiseEngine,
  IconMarketingDiscover,
} from 'maker/components/Icon/icon';

import { MARKETING_MUNU_ID } from './const';

export const MarketingData = {
  id: MARKETING_MUNU_ID,
  name: '增长引擎',
  icon: IconRiseEngine,
  children: [
    {
      id: 210,
      name: '经营沙盘',
      url: '/marketing/sandtable',
      icon: IconMarketingSandtable,
      component: dynamic({
        component: () => import('maker/routes/Marketing/sandtable'),
      }),
      children: [
      ],
    },
    {
      id: 213,
      name: '商机发现',
      url: '/marketing/discovery',
      icon: IconMarketingDiscover,
      component: dynamic({
        component: () => import('maker/routes/Marketing/discovery'),
      }),
      children: [
      ],
    },
  ],
};
