import {
  IconAICustomerCare,
  IconAICustomerServide,
  IconAIOfficialAccountArticle,
  IconAIOpeningTalk,
  IconAIPdfAnalyze,
  IconAIProductPromo,
} from 'maker/components/Icon/icon';

// 后台枚举
export enum AiLabTypeSceneEnum {
  HOME = 'not-scene-home', // 非场景，只是复用结构
  HISTORY = 'not-scene-history', // 非场景，只是复用结构
  PRODUCT_PROMO = 'productPromotion',
  PDF_ANALYZE = 'docExtract',
  OFFICIAL_ACCOUNT_ARTICLE = 'contentSummary',
  CUSTOMER_SERVICE = 'intelligentDialogue',
  OPEN_TALK = 'openingSentence',
  CUSTOMER_CARE = 'customerCare',
};

// 分类
export const AI_LAB_TYPE_TITLES = {
  MARKETING: '营销推广',
  OFFICE_EFFICIENCY: '办公提效',
  CUSTOMERS: '客户沟通',
};

// 类型命名。一些筛选项可以直接用。key为后台识别的类型
export const AI_LAB_NAMES: {
  [key in AiLabTypeSceneEnum]?: string;
} = {
  [AiLabTypeSceneEnum.PRODUCT_PROMO]: '商品推广文案',
  [AiLabTypeSceneEnum.PDF_ANALYZE]: '文档信息提炼',
  [AiLabTypeSceneEnum.OFFICIAL_ACCOUNT_ARTICLE]: '公众号摘要总结',
  [AiLabTypeSceneEnum.CUSTOMER_SERVICE]: '智能客服',
  [AiLabTypeSceneEnum.OPEN_TALK]: '开口破冰话术',
  [AiLabTypeSceneEnum.CUSTOMER_CARE]: '客户关怀',
};

// 链接
export const AI_LAB_LINKS: {
  [key in AiLabTypeSceneEnum]?: string;
} = {
  [AiLabTypeSceneEnum.HOME]: '/ai',
  [AiLabTypeSceneEnum.HISTORY]: '/ai/history',
  [AiLabTypeSceneEnum.PRODUCT_PROMO]: '/ai/productPromo',
  [AiLabTypeSceneEnum.PDF_ANALYZE]: '/ai/pdfAnalyze',
  [AiLabTypeSceneEnum.OFFICIAL_ACCOUNT_ARTICLE]: '/ai/officialAccountArticle',
  [AiLabTypeSceneEnum.CUSTOMER_SERVICE]: '/ai/customerService',
  [AiLabTypeSceneEnum.OPEN_TALK]: '/ai/openingTalk',
  [AiLabTypeSceneEnum.CUSTOMER_CARE]: '/ai/customerCare',
};

// icon 映射
export const AI_LAB_ICONS: {
  [key in AiLabTypeSceneEnum]?: (a: any) => JSX.Element | null;
} = {
  [AiLabTypeSceneEnum.HOME]: () => null,
  [AiLabTypeSceneEnum.HISTORY]: () => null,
  [AiLabTypeSceneEnum.PRODUCT_PROMO]: IconAIProductPromo,
  [AiLabTypeSceneEnum.PDF_ANALYZE]: IconAIPdfAnalyze,
  [AiLabTypeSceneEnum.OFFICIAL_ACCOUNT_ARTICLE]: IconAIOfficialAccountArticle,
  [AiLabTypeSceneEnum.CUSTOMER_SERVICE]: IconAICustomerServide,
  [AiLabTypeSceneEnum.OPEN_TALK]: IconAIOpeningTalk,
  [AiLabTypeSceneEnum.CUSTOMER_CARE]: IconAICustomerCare,
};

// 分组，筛选用
export const AI_LAB_TYPE_SCENE_OPTIONS = [
  {
    label: AI_LAB_TYPE_TITLES.MARKETING,
    value: AI_LAB_TYPE_TITLES.MARKETING,
    children: [
      {
        label: AI_LAB_NAMES[AiLabTypeSceneEnum.PRODUCT_PROMO],
        value: AiLabTypeSceneEnum.PRODUCT_PROMO,
      },
    ],
  },
  {
    label: AI_LAB_TYPE_TITLES.CUSTOMERS,
    value: AI_LAB_TYPE_TITLES.CUSTOMERS,
    children: [
      {
        label: AI_LAB_NAMES[AiLabTypeSceneEnum.CUSTOMER_SERVICE],
        value: AiLabTypeSceneEnum.CUSTOMER_SERVICE,
      },
      {
        label: AI_LAB_NAMES[AiLabTypeSceneEnum.OPEN_TALK],
        value: AiLabTypeSceneEnum.OPEN_TALK,
      },
      {
        label: AI_LAB_NAMES[AiLabTypeSceneEnum.CUSTOMER_CARE],
        value: AiLabTypeSceneEnum.CUSTOMER_CARE,
      },
    ],
  },
];
