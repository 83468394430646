/**
  转化宝菜单配置文件
  如果第一级有component，则走自定义逻辑，参考“AI助手”
 */

import dynamic from 'store/dynamic';
import {
  IconReportDashbord,
  IconReportAd,
  IconAdService,
  InconDataSource,
} from 'maker/components/Icon/icon';
import { DATA_MUNU_ID } from './const';
// import SubApp from 'maker/routes/SubApp/wujie';

export const DataReportData = {
  id: DATA_MUNU_ID,
  name: '广告服务',
  icon: IconAdService,
  children: [
    {
      id: 292,
      name: '腾讯广告优化',
      url: '/dataReport/adoptimize',
      icon: IconReportAd,
      component: dynamic({
        component: () => import('maker/routes/DataReport/adoptimize'),
      }),
    },
    {
      id: 291,
      name: '广告归因看板',
      url: '/dataReport',
      icon: IconReportDashbord,
      component: dynamic({
        component: () => import('maker/routes/DataReport/xiaomaData/index'),
      }),
    },
    {
      id: 401,
      name: '数据源接入',
      icon: InconDataSource,
      children: [
        {
          id: 341,
          name: '会话存档接入',
          url: '/wecomChatArchive',
          component: dynamic({
            component: () => import('maker/routes/AdPrivateTraffic/wecomChatArchive/index'),
          }),
        },
        {
          id: 322,
          name: '手动打标接入',
          url: '/scrm/qv-scrm/customer-marketing/chat-tools/customer-detail',
          component: dynamic({
            component: () => import('maker/routes/SubApp/wujie'),
          }),
        },
        {
          id: 342,
          name: '对外收款接入',
          url: '/wecomBillConfig',
          component: dynamic({
            component: () => import('maker/routes/AdPrivateTraffic/wecomBillConfig/index'),
          }),
        }],
    },
    /* {
    id: 23,
    name: '广告分析',
    disabled: false,
    icon: IconReportAd,
    children: [{
      id: 37,
      name: '渠道效果分析',
      url: '/dataReport/channels',
      component: dynamic({
        component: () => import('maker/routes/DataReport/channels'),
      }),
    }],
  }, {
    name: '员工分析',
    icon: IconMember,
    children:[{
      name: '人效分析',
      url: '/dataReport/members',
      component: dynamic({
        component: () => import('maker/routes/DataReport/members'),
      }),
    }],
  } */],
};
