import { Request } from '@common/requestHelper';
import { scrmApi } from 'maker/config';


// 获取企微配置列表
export const getWecomList = async () => {
  const res = await Request.post(scrmApi.getWecomList, { systemCode: 'CLIMB' }, {
    showError: false,
    headers: {
      sourcetype: 'CLIMB',
      'system-code': 'CLIMB',
    },
  });
  return res.getResponse();
};


// 获取企微配置列表
export const setDefaultCorp = async (corpId: string) => {
  const res = await Request.post(scrmApi.setDefaultCorp, { systemCode: 'CLIMB', corpId }, {
    showError: false,
    headers: {
      sourcetype: 'CLIMB',
      'system-code': 'CLIMB',
    },
  });
  return res.getResponse();
};
