// 全链路分析
import dynamic from 'store/dynamic';
import { ANALYSIS_ID } from './const';
import {
  IconLinkAnalysis,
  IconLinkFunnel,
} from 'maker/components/Icon/icon';

export const AnalysisData = {
  id: ANALYSIS_ID,
  name: '全链路分析',
  url: '/analysis',
  icon: IconLinkAnalysis,
  children: [{
    id: 22,
    name: '链路漏斗分析',
    url: '/analysis/funnel',
    icon: IconLinkFunnel,
    component: dynamic({
      component: () => import('maker/routes/LinkAnalysis/index'),
    }),
  }, {
    id: 220,
    icon: IconLinkFunnel,
    name: '用户画像分析',
    url: '/analysis/profile',
    component: dynamic({
      component: () => import('maker/routes/LinkAnalysis/index'),
    }),
  }],
};
