import React, { useState, useEffect, useMemo } from 'react';
import styles from './index.less?local';
import { Popover, message, Tooltip } from '@tencent/adp-combine-ui';
import { ArrowDown, ArrowUp } from '@tencent/adp-icons-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { getWecomList, setDefaultCorp } from 'maker/services/scrm';
import { useGlobalContext } from 'maker/hooks/useGlobalStore';
import Icon from 'maker/assets/layouts/company.svg';
import { queryWecomAuthoritylist } from 'maker/services/privateTraffic/wecomSidebar';
import {
  AuthoritylistResponse,
  // DataList,
} from 'maker/types/models/privateTraffic/wecomAuthorityList';
import { getCustomCookie, setCustomCookie } from 'maker/utils/session';
import { SvgInfoCopy } from 'maker/components/Icon/icon';

const DEFAULT_CORP_NAME = 'defaultCorpId';

const SelectCompotent = () => {
  const { state, dispatch } = useGlobalContext();
  const { corpInfo, history } = state;
  const [isShowPover, setShowPover] = useState<boolean>(false);
  const [corpList, setCorpList] = useState<any>([]);
  const [thirdCorpList, setThirdCorpList] = useState<any>(null);
  const [defaultCorpId, setDefaultCorpId] = useState<null | string>(null);

  // 获取待开发列表默认选中企微ID
  const fetchDefaultCorp = async () => {
    let defaultCorpId: null | string = '';
    const res = await getWecomList();
    if (res.code === 'Success') {
      const { corpInfoList } = res.data;
      if (corpInfoList.length > 0) {
        const filterCorp = corpInfoList.filter((corp: any) => corp.status === 2 && corp.isDefault);
        if (filterCorp.length > 0) {
          defaultCorpId = filterCorp[0].corpId;
        }
        setCorpList(corpInfoList.filter((corp: any) => corp.status === 2));
      }
    }
    const corpId = getCustomCookie(DEFAULT_CORP_NAME) || defaultCorpId;
    setDefaultCorpId(corpId);
    if (corpId) {
      setCustomCookie(DEFAULT_CORP_NAME, `${corpId}`);
    }
  };

  // 获取第三方列表
  const fetchThridCorp = async () => {
    const res = await queryWecomAuthoritylist({ pageNum: 1, pageSize: 1000 });
    if (res.isSuccess()) {
      const data: AuthoritylistResponse['data'] = res.getData();
      const { list } = data;
      setThirdCorpList(list.filter((corp: any) => corp.valid));
      // dev test

      /* setThirdCorpList([{
        corpId: 'wpalWwCwAARGskXEsjXjwr1gJqrkH-ig',
        corpName: 'test',
        isAuthConversation: 1,
      }]);*/
    } else {
      // 接口报错处理
      dispatch({
        type: 'setData',
        data: { corpInfo: {
          ...corpInfo,
          loading: false,
        } },
      });
    }
  };

  // 切换
  const changeCrop = async (corpId: string) => {
    setShowPover(false);
    if  (corpList.filter((corp: any) => corp.corpId === corpId).length !== 0) {
      const hide = message.loading('切换中..', 0);
      const res = await setDefaultCorp(corpId);
      hide();
      if (res.code === 'Success') {
        message.success('切换成功');
        if (history.location.pathname.startsWith('/scrm')
          && !history.location.pathname.startsWith('/scrm/setting')
          && !history.location.pathname.startsWith('/scrm/qv-scrm/customer-marketing/chat-tools/customer-detail')
        ) {
          setCustomCookie(DEFAULT_CORP_NAME, corpId);
          window.location.reload();
        } else {
          setDefaultCorpId(corpId);
          setCustomCookie(DEFAULT_CORP_NAME, corpId);
        }
      } else {
        message.warn('切换失败');
      }
    } else {
      setDefaultCorpId(corpId);
      setCustomCookie(DEFAULT_CORP_NAME, corpId);
      message.success('切换成功');
    }
  };


  // 处理数据
  useEffect(() => {
    if (thirdCorpList && defaultCorpId !== null) {
      // 第三方列表为空
      if (thirdCorpList.length === 0) {
        dispatch({
          type: 'setData',
          data: { corpInfo: {
            ...corpInfo,
            loading: false,
          } },
        });
        return;
      }

      let isReplace = true;
      let newCorpId = defaultCorpId;
      // 默认为空或默认选中不在第三方列表里
      if (defaultCorpId === '' || thirdCorpList.filter((corp: any) => corp.corpId === defaultCorpId).length === 0) {
        // 设置默认ID
        newCorpId = thirdCorpList[0].corpId;
      }

      if (corpList.filter((corp: any) => corp.corpId === newCorpId).length === 0) {
        isReplace = false;
      } else {
        setDefaultCorp(newCorpId); // 可以设为一个不存在的corpId
      }

      const newCorpInfo = thirdCorpList.filter((corp: any) => corp.corpId === newCorpId);
      dispatch({
        type: 'setData',
        data: { corpInfo: {
          loading: false,
          id: newCorpInfo[0].corpId,
          name: newCorpInfo[0].corpName,
          isConversation: newCorpInfo[0].isAuthConversation === 1,
          isReplace,
        } },
      });
    }
  }, [corpList, thirdCorpList, defaultCorpId]);

  // 初始化
  useEffect(() => {
    fetchDefaultCorp();
    fetchThridCorp();
  }, []);

  // 复制corpId
  const onCopy = () => {
    message.success('CorpID已复制至剪切板');
  };

  const renderDropList = useMemo(() => <>
    {!thirdCorpList || thirdCorpList.length === 0
      ? <> <div className={styles['popver-wrapper-selected-subtitle']}>暂无企微主体</div></>
      : <><div className={styles['popver-wrapper-selected-subtitle']}>当前企微主体</div>
        <div className={styles['popver-wrapper-selected-title']}>{corpInfo.name}</div>
        <div className={styles['popver-wrapper-selected-info']}>
          <label>CropID：</label>
          <div className={styles['popver-wrapper-selected-info-text']}>{corpInfo.id}</div>
          <CopyToClipboard text={corpInfo.id} onCopy={onCopy}>
            <div className={styles['popver-wrapper-selected-info-copy']}>
              <Tooltip
                theme="dark"
                trigger="hover"
                placement="top"
                getPopper="复制ID"
                lazyInit
                arrow
              >
                <SvgInfoCopy />
              </Tooltip>
            </div>
          </CopyToClipboard>

        </div>
        <div className={styles['popver-wrapper-select-list']}>
          {thirdCorpList.length > 1 && thirdCorpList.map((item: any, index: number) => (item.corpId !== corpInfo.id
            ? <div key={`list-${index}`}
              title={`CropID：${item.corpId}`}
              onClick={() => {
                changeCrop(item.corpId);
              }}
              className={`${styles['popver-wrapper-select-list-option']}`}>
              {item.corpName}
              <div>切换</div>
            </div>
            : null))}
        </div></>}

    <div className={styles['popver-wrapper-footer']}>
      找不到想要的主体？还可以
      <Link to="/wecomAuth" style={{ marginLeft: 8 }} target='_blank' onClick={() => {
        setShowPover(false);
      }}>去添加</Link>
    </div>
  </>, [corpInfo, thirdCorpList]);

  return useMemo(() => (
    <div className={styles['select-wrapper']}>
      <Popover
        trigger="click"
        show={isShowPover}
        placement="bottom-end"
        popoverStyle={{ padding: 0, marginTop: -5 }}
        getPopper={() => <div className={styles['popver-wrapper']}>
          {renderDropList}
        </div>}
        onVisibleChange={(v: boolean) => {
          setShowPover(v);
        }}
      >
        <div className={`${styles['selected-item']} ${isShowPover ? styles.selected : ''}`}>
          <span className={styles['selected-item-icon']}>
            <img src={Icon} alt="." />
          </span>
          <span className={styles['selected-item-name']}>
            {corpInfo.name}
          </span>
          <span className={styles['selected-item-drapdown']}>
            {isShowPover
              ? <ArrowUp fill="#4D5869" size={10} />
              : <ArrowDown fill="#4D5869" size={10} />}
          </span>
        </div>
      </Popover>
    </div>
  ), [corpInfo, isShowPover]);
};

export default SelectCompotent;
