// import MtaH5 from 'mta-h5-analysis';
import Aegis from '@tencent/aegis-web-sdk';

const aegis = new Aegis({
  // id: 'ZIvxTGYWKySATNWFXD', // 小店项目key
  id: 'ZIvxTGYWysvqFauEgk', // 转化宝项目key
  // uin: 'xxx', // 用户唯一 ID（可选）
  reportApiSpeed: true, // 接口测速,  1.Ajax（Fetch）请求异 2.retcode异常
  reportAssetSpeed: false, // 静态资源测速
  whiteListUrl: '',
  delay: 10000,
  api: {
    // 注意，此处如果用在ie低版本里面，不能用retCodeHandler(){}这种写法，ie不支持

    // NOCC:no-param-reassign(官方代码)
    retCodeHandler(data) {
      // 注意这里的 data 的数据类型，跟接口返回值的数据类型一致，开发者需要根据实际情况自行处理
      // fetch 请求的时候，data 是 string，如果需要对象需要手动 parse 下
      // xhr 请求的时候，data 是 xhr.response 拿到完整的后台响应
      try {
        // NOCC:no-param-reassign(官方代码)
        data = JSON.parse(data);
      } catch (e) { }
      return {
        isErr: (data.body?.code !== 200 && data.body?.code !== 'Success') || data.body?.retCode !== 0,
        code: data.body?.code || 0,
      };
    },
  },
});

// MtaH5.init({
//   sid: '500736250',
//   cid: '500736256',
//   autoReport: 1,
//   performanceMonitor: 1,
// });

export default aegis;
