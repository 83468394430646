
import './index.less';
import React from 'react';
import { Button, Tabs, TabPanel } from '@tencent/tea-component';
import soluad from 'maker/assets/homepage/solution-ad.png';
import soluOp from 'maker/assets/homepage/solution-oprate.png';
import soluGuide from 'maker/assets/homepage/solution-guide.png';
import solufullLink from 'maker/assets/homepage/solution-full-link.png';
import soluAi from 'maker/assets/homepage/solution-ai.png';
import soluTool from 'maker/assets/homepage/solution-tool.png';
import { useGlobalStore } from '@/maker/hooks/useGlobalStore';
const SolutionModule = () => {
  const { nickName, history } = useGlobalStore();
  const tabs = [
    { id: 'adService', label: '广告服务', contentInfo: {
      imgUrl: soluad,
      btnText: '免费使用',
      btnLink: '/dataReport/adoptimize',
      title: '串联广告全链路数据，\n实时优化广告效果',
      contentList: [{
        label: '打通广告前链路数据，洞察广告加粉来源',
        content: '支持查看客户广告来源数据，明确客户加粉线索资产，赋能商家制定个性化营销策略',
      }, {
        label: 'AI模型自动标注用户标签',
        content: '基于AI模型能力自动识别客户意向标签，标签可实时回传广告模型和实现精准细分场景营销',
      }],
    } },
    { id: 'operate', label: '增长引擎', contentInfo: {
      imgUrl: soluOp,
      btnText: '免费使用',
      btnLink: '/marketing/sandtable',
      title: '复刻销冠技巧，\n挖掘客户商机提升订单转化率',
      contentList: [{
        label: '挖掘客户营销商机',
        content: '综合AI标签、系统行为等数据综合建模，剖析客户当下意向层级，提供营销工具提升客户转化意向',
      }, {
        label: '监督导购服务人效',
        content: '量化导购服务质量，拆解导购服务过程指标，总结优质导购营销模版，复刻销冠技巧提升转化率',
      }],
    } },
    { id: 'shoppingGuide', label: '导购辅助', contentInfo: {
      imgUrl: soluGuide,
      btnText: '免费使用',
      btnLink: '/shoppingHelp/ai',
      title: '专属AI导购辅助 \n提升应答效率 打造专业服务',
      contentList: [{
        label: '智能生成sop，实时解析用户对话推荐专业话术',
        content: '可基于历史会话存档及企业信息生成sop知识库，企微端实时解析对话关联sop内容，提升导购专业度',
      }, {
        label: 'AI辅助应答，提升服务效率',
        content: '依托ai大模型，基于全网丰富数据、行业知识库、企业定制内容，实时生成导购话术，自主学习持续优化，辅助导购高效互动提升转化',
      }],
    } },
    { id: 'fullLink', label: '全链路分析', contentInfo: {
      imgUrl: solufullLink,
      btnText: '免费使用',
      title: '发挥全链路数据价值\n持续优化投放与运营效果',
      btnLink: '/analysis/funnel',
      contentList: [{
        label: '链路漏斗分析',
        content: '刻画从广告到私域运营的全链路转化行为，提供各个环节漏斗贡献度，漏斗用户画像等维度的分析',
      }, {
        label: '用户画像分析',
        content: '帮助客户看清转化、流失、高意向类型的广告用户画像特征和用户基础画像特征',
      }],
    } },
    { id: 'AI', label: 'AI体验室', contentInfo: {
      imgUrl: soluAi,
      btnText: '免费使用',
      btnLink: '/ai',
      title: '提供AI多模态生成能力\n提升营销内容创作工作效率',
      contentList: [{
        label: '营销内容智能创作',
        content: '专为营销而生，支持多风格的文案和图片内容生成，有效提升商品推广方案、营销文案撰写，创意灵感等内容工作效率',
      }, {
        label: '智能客服',
        content: '通过AI自由对话的方式，提供专业行业知识补充，让服务更专业、更高效',
      }],
    } },
    { id: 'operateTool', label: '运营工具箱', contentInfo: {
      imgUrl: soluTool,
      btnText: '免费使用',
      btnLink: '/scrm/qv-scrm/customer-manage/customer/list',
      title: '提供多样化企微经营工具\n助力商家营销提效',
      contentList: [{
        label: '广告引流获客提效',
        content: '打通落地页服务，提供渠道活码建设能力，支持设置个性化欢迎语',
      }, {
        label: '营销工具服务优化',
        content: '支持多触点群发工具，实现个性化群发优化服务质量',
      }],
    } },
  ];
  const gotoLink = (url) => {
    if (nickName) {
      history.push(url);
    } else {
      history.push('/login');
    }
  };
  const renderContent = (itemInfo, index) => {
    const { imgUrl, btnText, title, contentList, btnLink } = itemInfo;
    return (
      <div key={index} className='solution-item'>
        <div className='solution-item-content'>
          <img src={imgUrl} alt='' className='solution-item-content-img' />
          <div className='solution-content-show'>
            <div className='content-title'>{title}</div>
            <div className='content-split'></div>
            <div className='solution-content-show-module'>
              {
                contentList.map((item, i) => (
                  <div key={i}>
                    <div className='content-label'>{item.label}</div>
                    <div className='content-val'>{item.content}</div>
                  </div>
                ))
              }
            </div>

          </div>
        </div>
        <Button className='solution-item-btn' onClick={() => gotoLink(btnLink)}>{btnText}</Button>
      </div>
    );
  };

  return (
    <div className='module-solution'>
      <div className='module-solution-title'>
        提供企微全链路解决方案<br/>
        助力从广告域到经营全链路提效
      </div>
      <Tabs tabs={tabs} className='module-solution-tabs'>
        {
          tabs.map((item, index) => (
            <TabPanel id={item.id} key={index}>
              {renderContent(item.contentInfo, index)}
              {/* <Card>
                <Card.Body>{item.component}</Card.Body>
              </Card> */}
            </TabPanel>
          ))
        }
      </Tabs>
    </div>
  );
};

export default SolutionModule;
