
import './index.less';
import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@tencent/tea-component';
import rightSvg from 'maker/assets/homepage/right.svg';
import ExperienceModule from './ExperienceModule';
import SolutionModule from './SolutionModule';
import FullLinkMarketing from './FullLinkMarketing';
import Footer from './Footer';
import Logo from 'maker/assets/homepage/logo.png';
import itemUrl1 from 'maker/assets/homepage/header-item0-long.png';
import itemUrl2 from 'maker/assets/homepage/header-item1.png';
import itemUrl3 from 'maker/assets/homepage/header-item2.png';
import itemUrl4 from 'maker/assets/homepage/header-item3.png';
import headerBg from 'maker/assets/homepage/headerbg.png';
import headerBgMp4 from 'maker/assets/homepage/header-bg.mp4';
import { UserInfo } from '@/maker/components/MixSelect';
import { goHomepage } from 'maker/utils/router';
import { useGlobalStore } from '@/maker/hooks/useGlobalStore';

const AbilityModule = [
  {
    url: itemUrl1,
    title: '导购营销赋能',
    content: '为企业客户提供全流程的私域导购运营与管理服务',
  },
  {
    url: itemUrl2,
    title: '广告赋能',
    content: '数据实时反哺广告，一站式解决广告后链路数据归因问题',
  },
  {
    url: itemUrl3,
    title: 'AIGC赋能',
    content: '依托AI大模型提供更加智能的全链路服务能力',
  },
  {
    url: itemUrl4,
    title: '全链路数据赋能',
    content: '帮助调整广告策略与运营方案，持续优化投放与运营效果',
  },
];
const heightRadio = 0.313542; // 头图高宽比
const TempHomePage = () => {
  const state = useGlobalStore();
  const { nickName, history } = state;
  const [headerHeight, setHeaderHeight] = useState();
  const [isEnter, setIsEnter] = useState([false, false, false, false]);
  const rootRef = useRef(null);
  useEffect(() => {
    setHeight();
    window.addEventListener('resize', () => {
      setHeight();
    });
  }, []);
  const setHeight = () => {
    // 获取宽度
    if (rootRef.current) {
      const styles = window.getComputedStyle(rootRef.current);
      const width = parseFloat(styles.width);
      setHeaderHeight(width * heightRadio - 3);
    } else {
      setHeaderHeight(window.innerWidth * heightRadio - 3);
    }
  };
  const setEnterStatus = (index, stat) => {
    const newData = [...isEnter];
    newData[index] = stat;
    setIsEnter(newData);
  };
  const renderAbilityModule = (itemInfo, index) => (
    <div
      key={index}
      className='ability-show-item'
      onMouseEnter={() => setEnterStatus(index, true)}
      onMouseLeave={() => setEnterStatus(index, false)}
    >
      {/* <img src={itemInfo.url} alt="logo" className='' /> */}
      <div
        className={`ability-show-item-img ${isEnter[index] ? 'is-enter' : 'is-leave'}`}
        style={{ backgroundImage: `url(${itemInfo.url})` }}>
        {isEnter[index]}
      </div>
      <div className='ability-show-item-text'>
        <div className='ability-show-item-title'>{itemInfo.title}</div>
        <div className='ability-show-item-content'>{itemInfo.content}</div>
      </div>
    </div>
  );
  const gotoUse = () => {
    if (nickName) {
      goHomepage(state);
    } else {
      history.push('/login');
      // goLoginPage(history);
    }
  };
  return (
    <div className='all-home-page-module' ref={rootRef}>
      <div className='module-header' style={{ height: headerHeight }}>
        <div className="video-box">
          <video
            width="100%"
            height="auto"
            poster={headerBg}
            autoPlay
            loop
            preload="auto"
            muted>
            {/* <source src='https://admin.qa.zhb.qq.com/aaa.mp4' type="video/mp4" /> */}
            <source src={headerBgMp4} type="video/mp4" />
          </video>
        </div>
        <div className='module-header-content'>
          <div className='module-header-content-show'>
            <div className='login-module'>
              <div className='logo-show'>
                <img src={Logo} alt="logo" />
                <span>转化宝</span>
              </div>
              {!nickName ? <div>
                <Button className='login-btn' onClick={() => {
                  // goLoginPage(history);
                  history.push('/login');
                }}>登录</Button>
                <Button className='register-btn' onClick={() => {
                  history.push('/login/register');
                }}>免费注册</Button>
              </div> : <div>
                {/* {nickName} */}
                <UserInfo showName />
              </div>}

            </div>
            <div className='header-center-content'>
              <div className='header-main-title'>企微全链路营销增长</div>
              <div className='header-sub-title'>提供企微全链路解决方案，助力从广告到经营全链路提效</div>
              <Button className='go-to-use-btn' onClick={gotoUse}>
                <div className='btn-content'>
                  <span>免费使用</span>
                  <img src={rightSvg} alt="" />
                </div>
              </Button>
            </div>

          </div>
          <div className='ability-show-module'>
            {
              AbilityModule.map((item, index) => renderAbilityModule(item, index))
            }
          </div>
        </div>

      </div>
      <FullLinkMarketing />
      <SolutionModule />
      <ExperienceModule />
      <div className='module-conten-last'>
        <div>企微全链路营销增长</div>
        <div className='module-conten-2'>腾讯广告官方出品，用数据及AI能力，双擎驱动生意增长</div>
        <div className='module-conten-last-btn'>
          <Button className='last-go-to-use-btn1' onClick={gotoUse}>免费使用</Button>
          {/* <Button className='last-go-to-use-btn'>立即咨询</Button> */}
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default TempHomePage;
