import React, { useReducer, useEffect } from 'react';
import { MENU_DATA } from 'maker/config';
import { getUserInfo } from 'maker/services/zhb/auth';
import { getAuthToken } from 'maker/utils/session';
// import { getCompleteIdArray } from 'maker/routes/AccountManage/menuUtil';
import { goLoginPage } from 'maker/utils/router';
import { Menu } from 'maker/utils/menu';
import Context from './context';
import { useHistory } from 'react-router-dom';

type ACTIONTYPE = { type: 'setData' | 'refreshUserInfo'; data: any };

const reducer = (state: any, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'setData':
      return {
        ...state,
        ...action.data,
      };
    case 'refreshUserInfo':
      return {
        ...state,
        refreshUserInfo: state.refreshUserInfo + 1,
      };
    default:
      return state;
  }
};

const GlobalDataProvider = (props: any) => {
  const { children } = props;

  const initState = {
    MENU: new Menu(MENU_DATA, [], true),
    refreshUserInfo: 1,    // 用来刷新取用户信息
    nickName: '',
    phone: '',
    uid: '',
    fid: '',
    roleName: '',
    authList: [],          // 有权限的菜单id
    isAdmin: false,        // 是否管理员
    history: useHistory(),
    selectMenuIndex: -1,
    currUrlPath: '',
    corpInfo: {            // 第三方选中企微主体信息
      loading: true,
      id: '',
      name: '',
      isConversation: false, // 是否接入会话存档
      isReplace: false,    //  是否接入代开发
    },
  };

  // 初始化reducer
  const [state, dispatch] = useReducer(reducer, initState);

  // 获取登陆用户信息
  const fethUserInfo = async (isGoLogin: boolean) => {
    const token = getAuthToken();
    if (!token) {
      if (isGoLogin) {
        goLoginPage(state.history);
      }
      return;
    }

    // 获取用户登录鉴权接口信息
    const res = await getUserInfo(token);
    if (res.code !== 200) {
      if (isGoLogin) {
        goLoginPage(state.history);
        return;
      }
      return;
    }
    // userType: 1商家  2子账号
    const { data: { nickName, phone, zid, uid, Identitys, menuList, userType, uidIsBind, accountName, fid } } = res;

    let rName = '';
    if (Identitys && Identitys.length > 0) {
      rName = Identitys[0].roleName;
    }
    let uiUidIsBind = uidIsBind;
    if (uiUidIsBind === undefined) {
      uiUidIsBind = true;
    }

    const isAdmin = menuList.length === 0 || (menuList.length === 1 && menuList[0] === 0); // 只有1个元素0 也为管理员

    state.MENU.initMenuData(MENU_DATA, menuList, isAdmin);
    dispatch({
      type: 'setData',
      data: {
        nickName,
        phone,
        fid,
        zid,
        uid,
        roleName: rName,
        authList: menuList,
        isAdmin,
        userType,
        uidIfBind: uiUidIsBind,
        accountName,
      },
    });
  };

  // 初始化
  useEffect(() => {
    if ((!window.location.pathname.startsWith('/login') && !window.location.pathname.startsWith('/normal'))
    || state.refreshUserInfo > 1) {
      fethUserInfo(!(window.location.pathname === '' || window.location.pathname === '/'));
    }
  }, [state.refreshUserInfo]);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export default GlobalDataProvider;
