import './index.less';
import React from 'react';

const outsideList = [
  {
    title: '增长引擎',
    content: (<>复刻销冠技巧、挖掘客户<br />商机提升订单转化率</>),
    style: {
      right: 65,
      top: 15,
    },
  },
  {
    title: '全链路分析',
    content: '发挥全链路数据价值，提供链路漏斗和用户画像分析',
    style: {
      right: -80,
      top: 'calc(50% - 98px)',
      // transform: 'translate(40%, -50%)'
    },
  },
  {
    title: 'AI体验室',
    content: '提供AI多模态生成能力，提升营销内容创作工作效率',
    style: {
      right: 65,
      bottom: 0,
    },
  },
  {
    title: '经营工具箱',
    content: '提供多样化企微经营工具，助力商家营销提效',
    style: {
      left: 65,
      bottom: 0,
    },
  },
  {
    title: '导购辅助',
    content: '专属AI导购辅助 提升应答效率 打造专业服务',
    style: {
      left: -80,
      top: 'calc(50% - 98px)',
      // transform: 'translate(-40%, -50%)'
    },
  },
  {
    title: '广告服务',
    content: '私域后链路数据自动上报，可监测全链路广告效果',
    style: {
      left: 65,
      top: 15,
    },
  },
];
const insideList = [
  // '获客', '识客', '营销转化', '存量营销', '数据驱动'
  {
    label: '获客',
    style: {
      top: 157,
      left: 'calc(50% - 60px)',
    },
  },
  {
    label: '识客',
    style: {
      top: 265,
      right: 110,
    },
  },
  {
    label: '营销转化',
    style: {
      bottom: 207,
      right: 153,
    },
  },
  {
    label: '存量营销',
    style: {
      bottom: 207,
      left: 153,
    },
  },
  {
    label: '数据驱动',
    style: {
      top: 265,
      left: 110,
    },
  },
];

const FullLinkMarketing = () => {
  const renderOuside = (item, index) => (
    <div className='center-content-item' key={index} style={{
      transformOrigin: 'center',
      ...item?.style,
    }}>
      <div className='center-content-show'>
        <span className='item-title'>{item.title}</span>
        <span>{item.content}</span>   
      </div>
    </div>
  );
  const renderInside = (item, index) => (
    <div className='center-content-item-inside' key={index} style={{
      ...item.style,
    }}>
      <div className='center-content-show-inside'>
        {item.label}
      </div>
    </div>
  );
  return (
    <div className='full-link-marketing'>
      <div className='center-content'>
        <div className='center-content-list-ouside'>
          {
            outsideList.map((item, index) => renderOuside(item, index))
          }
          {
            insideList.map((item, index) => renderInside(item, index))
          }
        </div>
        <div className='center-content-core'>
          全链路<br />营销
        </div>
      </div>

    </div>
  );
};

export default FullLinkMarketing;
