/**
 * 菜单类
 */
export class Menu {
  // 菜单数据
  menuData: any[] = [];

  // 类初始化
  constructor(menuData: any[], authList: number[], isAdmin: boolean) {
    // this.menuData = menuData;
    this.initMenuData(menuData, authList, isAdmin);
  }

  // 菜单数据初始化
  initMenuData(data: any[], authList: number[], isAdmin: boolean) {
    const isAuth = (id: number) => isAdmin || authList.includes(id);
    const loopAllChild: any = (data: any, parentId: number) => {
      const newItem: any = [];
      for (const item of data) {
        if (item.children && item.children.length > 0) {
          const child: any[] = loopAllChild(item.children, item.id);
          if (child.length > 0) {
            newItem.push({ ...item, children: child, parentId });
          }
        } else {
          newItem.push({ ...item, parentId });
        }
      }
      return newItem;
    };
    const loopChild: any = (data: any, parentId: number) => {
      const newItem: any = [];
      for (const item of data) {
        if (isAuth(item.id)) {
          if (item.children && item.children.length > 0) {
            const child: any[] = loopAllChild(item.children, item.id);
            if (child.length > 0) {
              newItem.push({ ...item, children: child, parentId });
            }
          } else {
            newItem.push({ ...item, parentId });
          }
        }  else if (item.children && item.children.length > 0) {
          const child: any[] = loopChild(item.children, item.id);
          if (child.length > 0) {
            newItem.push({ ...item, children: child, parentId });
          }
        }
      }
      return newItem;
    };
    this.menuData = loopChild(data, 0);
    console.log(this.menuData);
    return this;
  }

  // 获取第一个有权限的菜单
  getFirstMenu(parentIndex?: number) {
    const loopChild: any = (data: any) => {
      for (const item of data) {
        if (item.menuType === 'title') continue;
        if (item.children && item.children.length > 0) {
          return loopChild(item.children);
        } if (item.url && item.name) {
          return item;
        }
      }
      return null;
    };
    if (parentIndex === undefined) {
      return loopChild(this.menuData);
    } if (this.menuData[parentIndex].children && this.menuData[parentIndex].children.length > 0) {
      return loopChild(this.menuData[parentIndex].children);
    } if (this.menuData[parentIndex].url) {
      return this.menuData[parentIndex];
    }
    return null;
  }

  // 获取菜单的名称
  getMenuNames(menuIds: number[]) {
    const menuNames: string[] = [];
    const loopChild: any = (data: any) => {
      for (const item of data) {
        if (menuIds.includes(item.id) && item.name) {
          menuNames.push(item.name);
        } else if (item.children && item.children.length > 0) {
          loopChild(item.children, item.id);
        }
      }
    };
    loopChild(this.menuData);
    return menuNames;
  }
};
