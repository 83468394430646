import React, { ReactElement, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import './aiLabSidderMenu.less';
import { AI_LAB_LINKS, AiLabTypeSceneEnum } from '@/maker/common/aiLabConst';
// import { SvgButtonLeftArrow } from './icon';
import withWhitelist from '@/maker/use/withWhitelist';
import { WHITE_LIST } from '@/maker/common/systemConst';
import { ArrowRightOne } from '@tencent/adp-icons-react';

const MenuContext = createContext({
  menuItemCount: 0,
  addMenuItemCount: () => { },
});

type TypeAiLabMenuProps = {
  curNavMenu: {
    children: Array<{
      id: number;
      key: string;
      name: string; // 名称
      url: string; // url
      icon: () => JSX.Element;
      component: Promise<any>;

      menuType: 'title' | undefined; // 展示类型。title的话就是纯文本

      hideMenuItem: boolean; // 是否隐藏该menu项
    }>;
  };
};

export default withWhitelist(
  AiLabSidderMenu,
  WHITE_LIST.AI_LAB,
);

function AiLabSidderMenu(props: TypeAiLabMenuProps): JSX.Element {
  // export default function AiLabSidderMenu(props: TypeAiLabMenuProps): JSX.Element {
  const [menuItemCount, setMenuItemCount] = useState(0);
  const addMenuItemCount = useCallback<() => void>(() => {
    setMenuItemCount(prevMenuItemCount => prevMenuItemCount + 1);
  }, [setMenuItemCount]);

  return (
    <MenuContext.Provider value={{
      menuItemCount,
      addMenuItemCount,
    }}>
      <div className="ai-lab-sidder-menu">
        {/* menuItemCount: {menuItemCount} */}
        <ul>
          {
            props.curNavMenu.children.map((menuItem) => {
              // 不可见
              if (menuItem.hideMenuItem) {
                return null;
              }
              // 标题
              if (menuItem.menuType === 'title') {
                return (
                  <li key={menuItem.name}>
                    <div className="ai-lab-sidder-menu__title">{menuItem.name}</div>
                  </li>
                );
              }
              // 普通菜单
              const Icon = menuItem.icon;
              return (
                <li key={menuItem.key}>
                  <NavLink
                    exact={true}
                    to={menuItem.url}
                    className="ai-lab-sidder-menu__link"
                  >
                    <MenuItem
                      className="ai-lab-sidder-menu__link__item"
                      icon={<Icon />}
                      name={menuItem.name}
                    />
                  </NavLink>
                </li>
              );
            })
          }
          {/* <div>{JSON.stringify(props)}</div> */}
        </ul>
      </div>

      {/* 首页 */}
      <Link to={AI_LAB_LINKS[AiLabTypeSceneEnum.HISTORY]}>
        <div className="ai-lab-sidder-menu__go-home">
          {/* <i><SvgButtonLeftArrow color="currentColor" /></i> */}
          <span>查看历史创作</span>
          <i><ArrowRightOne /></i>
        </div>
      </Link>
    </MenuContext.Provider>
  );
}

function MenuItem(props: {
  className: string;
  icon: ReactElement;
  name: string;
}): JSX.Element {
  const { addMenuItemCount } = useContext(MenuContext);
  const bgElementRef = useRef(null);

  useEffect(() => {
    addMenuItemCount();
  }, [addMenuItemCount]);

  return (
    <div className={props.className}>
      <div className="bg" ref={bgElementRef}></div>
      <span className="icon">{props.icon}</span>
      <span>{props.name}</span>
    </div>
  );
}
