import React, { useMemo } from 'react';
import { Spin } from '@tencent/adp-combine-ui';
import { Container } from 'maker/components/zhb/container';
import Empty from 'maker/components/Empty';

import { useGlobalStore } from '@/maker/hooks/useGlobalStore';
import { Link } from 'react-router-dom';

const WithCheckCorp = (OriginalComponent: any, checkReplace?: boolean) => {
  const NewComponent = (props: any) => {
    const { corpInfo } = useGlobalStore();

    return useMemo(() => {
      if (corpInfo.loading) {
        return <div style={{ textAlign: 'center' }}><Spin /></div>;
      }

      if (corpInfo.id === '') { // 未绑定第三方企微
        return <Container title=""  hasBG><Empty
          emptyTips={
            <>
            请前往 <Link to="/wecomAuth" target='_blank'>【设置-广告应用授权】</Link> 进行授权操作
            </>
          }
        /></Container>;
      }

      // scrm/qv-scrm/customer-marketing/chat-tools/customer-detail
      if (checkReplace && !corpInfo.isReplace
        && !window.location.pathname.startsWith('/scrm/setting')
        && !window.location.pathname.startsWith('/scrm/qv-scrm/customer-marketing/chat-tools/customer-detail')
      ) { // 未绑定代开发
        return <Container title=""  hasBG><Empty
          emptyTips={
            <>
            「{corpInfo.name}」暂未进行企微营销授权 <br />
            请前往 <Link to="/scrm/setting/auth/wxwork" target='_blank'>【设置-企微营销授权】</Link> 进行授权操作
            </>
          }
        /></Container>;
      }

      return (
        <OriginalComponent
          corpId={corpInfo.id}
          corpName={corpInfo.name}
          isBind={corpInfo.isConversation}
          {...props}
        />
      );
    }, [corpInfo]);
  };
  return NewComponent;
};

export default WithCheckCorp;
